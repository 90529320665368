/**
 * Created by PugachevIgor on 23.11.2015.
 */


app.controller('RegionsListController', ['$scope', '$http', 'ngDialog',
    function ($scope, $http, ngDialog) {
        var url = '/region';

        $scope.getRegionsJson = function () {
            console.log('asd');
            $http.get('/getJson').then(function (result) {
                console.log(result.data);
            })
        };

        var getRegions = function () {
            $http.get(url).then(function (result) {
                $scope.regions = result.data;
            })
        };

        var addRegion = function (region) {
            $http.post(url, region).then(function (result) {
                $scope.regions.push(result.data);
                region.name = null;
            })
        };

        var updateRegion = function (region) {
            $http.put(url + '/' + region.id, region).then(function (result) {
                console.log(result.data);
            });
        };

        var selectRegion = function (region) {
            $scope.selectedRegion = region;
        };

        var deleteRegion = function (index, event) {
            event.stopPropagation();

            $scope.deletedRegion = $scope.regions[index];

            ngDialog.openConfirm({
                scope: $scope,
                template: 'templates/dialogs/deleteRegion.html',
            }).then(function (value) {
                $http.delete(url + '/' + $scope.regions[index].id).then(function () {
                    $scope.regions.splice(index, 1);
                })
            });
        };


        $scope.regions = [];
        $scope.newRegion = {};
        $scope.addRegion = addRegion;
        $scope.deleteRegion = deleteRegion;
        $scope.updateRegion = updateRegion;
        $scope.selectRegion = selectRegion;

        $scope.selectedRegion = {};
        $scope.deletedRegion = {};

        getRegions();
    }]);