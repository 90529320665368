app.controller('UsersController', ['$scope', '$http', 'ngDialog',
    function ($scope, $http, ngDialog) {

        function getUsers() {
            $http.get('/users').then(function (result) {
                $scope.users = result.data;
            });
        }

        $scope.openAddUserDialog = function () {
            ngDialog.openConfirm({
                scope: $scope,
                template: 'templates/dialogs/addUser.html'
            }).then(function (user) {
                createUser(user);
            });
        };

        $scope.openUpdateUserDialog = function (user) {
            $scope.updatingUser = user;
            ngDialog.openConfirm({
                scope: $scope,
                template: 'templates/dialogs/updateUser.html'
            }).then(function (user) {
                updateUser(user);
            });
        };

        function updateUser(user) {
            $http.put('/users/' + user.id, user).then(function () {
                $scope.updatingUser = {};
            });
        }

        function createUser(user) {
            $http.post('/users', user).then(function (result) {
                $scope.users.push(result.data);
                $scope.newUser = {};
            });
        }

        function deleteUser(user) {
            $http.delete('/users/' + user.id).then(function () {
                angular.forEach($scope.users, function (item, index) {
                    console.log(index);
                    if(item.id == user.id) {
                        $scope.users.splice(index, 1);
                    }
                });
            });
        }

        $scope.openDeleteDialog = function(user) {
            $scope.deletingUser = user;
            ngDialog.openConfirm({
                scope: $scope,
                template: 'templates/dialogs/deleteUser.html'
            }).then(function (user) {
                deleteUser(user);
            });
        };

        $scope.permissions = [
            {id: 1, permission: 'Пользователь'}
        ];

        $scope.users = [];
        $scope.newUser = {};
        $scope.updatingUser = {};
        $scope.deletingUser = {};
        getUsers();
    }
]);