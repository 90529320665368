/**
 * Created by PugachevIgor on 23.11.2015.
 */


app.controller('ApparatTypesListController', ['$scope', '$http', 'ngDialog',
    function ($scope, $http, ngDialog) {
        var url = '/apparat-type';

        var getApparatTypes = function () {
            $http.get(url).then(function (result) {
                $scope.apparatTypes = result.data;
            })
        };

        var addApparatType = function (type) {
            $http.post(url, type).then(function (result) {
                $scope.apparatTypes.push(result.data);
                type.name = null;
            })
        };

        var updateApparatType = function (type) {
            $http.put(url + '/' + type.id, type).then(function (result) {
                console.log(result.data);
            });
        };

        var selectApparatType = function (type) {
            $scope.selectedApparatType = type;
        };

        var deleteApparatType = function (index, event) {
            event.stopPropagation();

            $scope.deletedApparatType = $scope.apparatTypes[index];

            ngDialog.openConfirm({
                scope: $scope,
                template: 'templates/dialogs/deleteRegion.html',
            }).then(function (value) {
                $http.delete(url + '/' + $scope.apparatTypes[index].id).then(function () {
                    $scope.apparatTypes.splice(index, 1);
                })
            });


        };


        $scope.apparatTypes = [];
        $scope.newApparatType = {};
        $scope.addApparatType = addApparatType;
        $scope.deleteApparatType = deleteApparatType;
        $scope.updateApparatType = updateApparatType;
        $scope.selectApparatType = selectApparatType;

        $scope.selectedApparatType = {};
        $scope.deletedApparatType = {};

        getApparatTypes();
    }]);