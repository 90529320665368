/**
 * Created by PugachevIgor on 24.11.2015.
 */


app.config(function($stateProvider, $urlRouterProvider) {
    //
    // For any unmatched url, redirect to /state1
    $urlRouterProvider.otherwise("/");
    var tpl_path = '../templates/';
    //
    // Now set up the states
    $stateProvider
        .state('regions', {
            url: "/regions",
            templateUrl: tpl_path + "regions/regions.list-tpl.html",
            controller: 'RegionsListController',
        })
        .state('users', {
            url: "/users",
            templateUrl: tpl_path + "users/users.list-tpl.html",
            controller: 'UsersController',
        })
        .state('regions.edit', {
            url: "/:region_id",
            views: {
                'cities': {
                    templateUrl: tpl_path + "cities/cities.list-tpl.html",
                    controller: "CitiesListController"
                }
            }
        })
        .state('regions.edit.editcity', {
            url: "/city/:city_id",
            views: {
                'hospitals@regions.edit': {
                    templateUrl: tpl_path + "hospitals/hospitals.list-tpl.html",
                    controller: "HospitalsListController"
                }
            }
        })
        .state('hospitals', {
            url: "/hospitals",
            templateUrl: tpl_path + "hospitals/hospitals.list-tpl.html",
            controller: "HospitalsListController"
        })
        .state('manage', {
            url: "/manage",
            templateUrl: tpl_path + "manage/manage.list-tpl.html",
            controller: "ManageListController"
        })
        .state('apparatTypes', {
            url: "/apparat-types",
            templateUrl: tpl_path + "apparatTypes/apparatTypes.list-tpl.html",
            controller: "ApparatTypesListController"
        })
        .state('apparatTypes.apparatus', {
            url: "/:type_id",
            views: {
                'apparatus': {
                    templateUrl: tpl_path + "apparatus/apparatus.list-tpl.html",
                    controller: "ApparatusListController"
                }
            }
        });
});