/**
 * Created by PugachevIgor on 01.02.2016.
 */

app.controller('MenuController', ['$scope', 'eehNavigation', '$http',
    function ($scope, eehNavigation, $http) {
        $http.post('/users/authUser').then(function (result) {
            var user = result.data;
            eehNavigation.menuItem('foo.user').text = user.first_name + ' ' + user.last_name;
        });
    }]);