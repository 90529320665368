/**
 * Created by PugachevIgor on 23.11.2015.
 */


app.controller('ApparatusListController', ['$scope', '$http', 'ngDialog', '$stateParams',
    function ($scope, $http, ngDialog, $stateParams) {
        var url = '/apparat-type/' + $stateParams.type_id + '/apparat';

        var getApparatus = function () {
            $http.get(url).then(function (result) {
                $scope.apparatus = result.data;
            })
        };

        var addApparat = function (apparat) {
            apparat.type_id = $stateParams.type_id;
            $http.post(url, apparat).then(function (result) {
                $scope.apparatus.push(result.data);
                apparat.name = null;
            })
        };

        var updateApparat = function (apparat) {
            $http.put(url + '/' + apparat.id, apparat).then(function (result) {
                console.log(result.data);
            });
        };

        var selectApparat = function (apparat) {
            $scope.selectedApparat = apparat;
        };

        var deleteApparat = function (index, event) {
            event.stopPropagation();

            $scope.deletedApparat = $scope.apparatus[index];

            ngDialog.openConfirm({
                scope: $scope,
                template: 'templates/dialogs/deleteApparat.html',
            }).then(function (value) {
                $http.delete(url + '/' + $scope.apparatus[index].id).then(function () {
                    $scope.apparatus.splice(index, 1);
                })
            });
        };


        $scope.deletedApparat = {};
        $scope.apparatus = [];
        $scope.newApparat = {};
        $scope.addApparat = addApparat;
        $scope.deleteApparat = deleteApparat;
        $scope.updateApparat = updateApparat;
        $scope.selectApparat = selectApparat;

        $scope.selectedApparat = {};
        $scope.deletedApparat = {};

        getApparatus();
    }]);