/**
 * Created by PugachevIgor on 23.11.2015.
 */


var app = angular.module('diagalApp', [
    'eehNavigation',
    'pascalprecht.translate',
    'ui.bootstrap',
    'ui.router',
    'ngDialog',
    'xeditable',
    'ngSanitize',
    'ui.select',
    'ngTable',
    'cgBusy',
    'ui.bootstrap.datetimepicker',
    'ui-notification'
]).config(['eehNavigationProvider', function (eehNavigationProvider) {
    eehNavigationProvider
        .menuItem('foo.user', {
            text: '',
            iconClass: 'fa fa-user'
        })
        .menuItem('foo.user.profile', {
            text: 'Выход',
            iconClass: 'fa fa-user',
            href: 'auth/logout'
        });

    eehNavigationProvider
        .menuItem('bar.home', {
            text: 'Главная',
            iconClass: 'fa fa-home',
            href: '/'
        })
        .menuItem('bar.regions', {
            text: 'Регионы',
            iconClass: 'fa fa-globe',
            href: '#/regions'
        })
        .menuItem('bar.users', {
            text: 'Пользователи',
            iconClass: 'fa fa-users',
            href: '#/users'
        })
        .menuItem('bar.apparatus', {
            text: 'Аппараты',
            iconClass: 'fa fa-heartbeat',
            href: '#/apparat-types'
        })
        .menuItem('bar.hospitals', {
            text: 'Больницы',
            iconClass: 'fa fa-hospital-o',
            href: '#/hospitals'
        })
        .menuItem('bar.manage', {
            text: 'Управление',
            iconClass: 'fa fa-list-alt',
            href: '#/manage'
        })
}]).run(function(editableOptions) {
    editableOptions.theme = 'bs3'; // bootstrap3 theme. Can be also 'bs2', 'default'
}).directive('icheck', function ($timeout, $parse) {
    return {
        require: 'ngModel',
        link: function ($scope, element, $attrs, ngModel) {
            return $timeout(function () {
                var value;
                value = $attrs['value'];

                $scope.$watch($attrs['ngModel'], function (newValue) {
                    $(element).iCheck('update');
                });

                $scope.$watch($attrs['ngDisabled'], function (newValue) {
                    $(element).iCheck(newValue ? 'disable' : 'enable');
                    $(element).iCheck('update');
                });

                return $(element).iCheck({
                    checkboxClass: 'icheckbox_minimal-blue',
                    radioClass: 'iradio_minimal-blue'

                }).on('ifChanged', function (event) {
                    if ($(element).attr('type') === 'checkbox' && $attrs['ngModel']) {
                        $scope.$apply(function () {
                            return ngModel.$setViewValue(event.target.checked);
                        })
                    }
                }).on('ifClicked', function (event) {
                    if ($(element).attr('type') === 'radio' && $attrs['ngModel']) {
                        return $scope.$apply(function () {
                            //set up for radio buttons to be de-selectable
                            if (ngModel.$viewValue != value)
                                return ngModel.$setViewValue(value);
                            else
                                ngModel.$setViewValue(null);
                            ngModel.$render();
                            return
                        });
                    }
                });
            });
        }
    };
}).config(function(NotificationProvider) {
    NotificationProvider.setOptions({
        delay: 10000,
        startTop: 20,
        startRight: 10,
        verticalSpacing: 20,
        horizontalSpacing: 20,
        positionX: 'right',
        positionY: 'top'
    });
});