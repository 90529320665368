/**
 * Created by PugachevIgor on 24.11.2015.
 */


app.controller('RegionsEditController', ['$scope', '$http', '$stateParams',
    function ($scope, $http, $stateParams) {
        var url = '/region/';

        var getRegion = function () {
            $http.get(url+$stateParams.region_id).then(function (result) {
                $scope.region = result.data;
            })
        };

        $scope.region = {};
        getRegion();
    }]);