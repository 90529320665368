/**
 * Created by PugachevIgor on 23.11.2015.
 */
"use strict";

app.controller('ManageListController', ['$scope', '$http', 'ngDialog', 'NgTableParams', 'Notification',
    function ($scope, $http, ngDialog, NgTableParams, Notification) {
        var url = '/manage';

        moment.locale('ru');

        function getData() {
            $scope.myPromise = $http.get(url).then(function (result) {
                $scope.exportData = result.data;
                angular.forEach(result.data, function (item) {
                    var serial = {};
                    serial.name = item.serial;
                    $scope.fserials.push(serial);
                });
                $scope.tp = new NgTableParams({}, {dataset: result.data});
            });
        }

        function getCols() {
            if (localStorage.getItem('cols')) {
                var cols = JSON.parse(localStorage.getItem('cols'));
                $scope.cols = cols;
            } else {
                $scope.cols = [
                    {field: "hospital.city.region.name", title: 'Область', show: true},
                    {title: 'Город', show: true},
                    {title: 'Мед. учреждение', show: true},
                    {title: 'Аппарат', show: true},
                    {title: 'Серийный номер', show: true},
                    {title: 'Год выпуска', show: true},
                    {title: 'Поставщик', show: true},
                    {title: 'Номер договора', show: true},
                    {title: 'Дата установки', show: true},
                    {title: 'Гарантия', show: true},
                    {title: 'КМТ', show: true},
                    {title: 'Номер договора на ТО', show: true},
                    {title: 'Дата окончания договора на ТО', show: true}
                ];
            }
        }

        $scope.getDate = function (date) {
            if (moment(date).format('LL') == 'Invalid date') return '';
            return moment(date).format('LL');
        };

        $scope.groupFind = function (item) {
            return item.type.name;
        };

        function getRegions() {
            $scope.myPromise = $http.get('/region').then(function (result) {
                $scope.regions = result.data;
            })
        }

        function selectFilterRegion(item) {
            $scope.fcity = {};
            $scope.fhospital = {};
            $scope.fapparat = {};
            if (item) {
                $http.get('/region/' + item.id + '/city').then(function (result) {
                    selectFilterHospitalByRegion(item);
                    //selectFilterApparatsByRegion(item);
                    $scope.fcities = result.data;
                });
            }
            filteringData();
        }

        function selectFilterCity(item) {
            $scope.fhospital = {};
            $scope.fapparat = {};
            if ($scope.fregion.selected) {
                selectFilterHospitalByCityAndRegion($scope.fregion.selected, item);
            } else {
                selectFilterHospitalByCity(item);
            }
        }

        function selectFilterHospitalByCity(city) {
            $http.get('/hospital/getByCity/' + city.id).then(function (result) {
                $scope.fhospitals = result.data;
                if (!result.data.length) {
                    $scope.fhospitals = [];
                }
                filteringData();
            })
        }

        function selectFilterApparat(item) {
            filteringData();
        }

        function selectFilterHospitalByRegion(region) {
            $http.get('/hospital/getByRegion/' + region.id).then(function (result) {
                $scope.fhospitals = result.data;
                if (!result.data.length) {
                    $scope.fhospitals = [];
                }
            })
        }

        function selectFilterHospitalByCityAndRegion(region, city) {
            $scope.fhospital = {};
            $scope.fapparat = {};
            $http.get('/hospital/getByRegionCity/' + region.id + '/' + city.id).then(function (result) {
                $scope.fhospitals = result.data;
                if (!result.data.length) {
                    $scope.fhospitals = [];
                }
                filteringData();
            })
        }

        function selectFilterHospital(item) {
            $scope.fapparat = {};
            if (item) {
                $http.get('/apparat/getByHospital/' + item.id).then(function (result) {
                    $scope.fapparats = result.data;
                });
            }
            filteringData();
        }

        function selectFilterApparatsByRegion(region) {
            $scope.fapparat = {};
            $http.get('/apparat/getByRegion/' + region.id).then(function (result) {
                console.log(result.data);
                //$scope.fapparats = result.data;
            });
        }


        var selectRegion = function (item) {
            $scope.myPromise = $http.get('/region/' + item.id + '/city').then(function (result) {
                selectHospitalByRegion(item);
                $scope.cities = result.data;
                if (result.data.length) {
                    $scope.city = result.data[0];
                } else {
                    $scope.city = {};
                    $scope.city.selected = {};
                }
            })
        };

        var selectCity = function (item) {
            selectHospitalByCityAndRegion($scope.region.selected, item);
        };

        function selectHospitalByCityAndRegion(region, city) {
            $scope.myPromise = $http.get('/hospital/getByRegionCity/' + region.id + '/' + city.id).then(function (result) {
                $scope.hospitals = result.data;
                if (result.data.length) {
                    $scope.hospital = result.data[0];
                    $scope.hospital.selected = result.data[0];
                } else {
                    $scope.hospital = {};
                    $scope.hospital.selected = {};
                }
            })
        }

        function selectHospitalByRegion(region) {
            $scope.myPromise = $http.get('/hospital/getByRegion/' + region.id).then(function (result) {
                $scope.hospitals = result.data;
                if (result.data.length) {
                    $scope.hospital = result.data[0];
                    $scope.hospital.selected = result.data[0];
                } else {
                    $scope.hospital = {};
                    $scope.hospital.selected = {};
                }
            })
        }

        function getFilterData() {
            $http.get('/apparat/getAll').then(function (result) {
                $scope.fapparats = result.data;
            });
            $http.get('/region').then(function (result) {
                $scope.fregions = result.data;
            });
            $http.get('/hospital').then(function (result) {
                $scope.fhospitals = result.data;
            });
            $http.get('/city/getAll').then(function (result) {
                $scope.fcities = result.data;
            });
        }

        function filteringData() {
            var data = {};
            if ($scope.fregion.selected) data.region_id = $scope.fregion.selected.id;
            if ($scope.fcity.selected) data.city_id = $scope.fcity.selected.id;
            if ($scope.fhospital.selected) data.hospital_id = $scope.fhospital.selected.id;
            if ($scope.fapparat.selected) data.apparat_id = $scope.fapparat.selected.id;

            $scope.myPromise = $http.post('/manage/filter', data).then(function (result) {
                $scope.exportData = result.data;
                $scope.tp = new NgTableParams({}, {dataset: result.data});
            });
        }


        function getApparats() {
            $scope.myPromise = $http.get('/apparat/getAll').then(function (result) {
                $scope.apparats = result.data;
            })
        }

        function getHospitalByRegionAndCity(region, city) {
            $scope.myPromise = $http.get('/hospital/getByRegionCity/' + region.id + '/' + city.id).then(function (result) {
                $scope.hospitals = result.data;
            });
        };

        function getHospitalByRegion(region) {
            $scope.myPromise = $http.get('/hospital/getByRegion/' + region.id).then(function (result) {
                $scope.hospitals = result.data;
            });
        }

        function getCityByRegion(region) {
            $scope.myPromise = $http.get('/region/' + region.id + '/city').then(function (result) {
                $scope.cities = result.data;
                if (result.data.length) {
                    getHospitalByRegionAndCity(region, $scope.city.selected);
                } else {
                    getHospitalByRegion(region);
                }
            })
        }

        function deleteManage(manage) {
            ngDialog.openConfirm({
                scope: $scope,
                template: 'templates/dialogs/deleteManage.html',
            }).then(function (value) {
                $http.delete('/manage/' + manage.id).then(function () {
                    for (var i in $scope.tp.data) {
                        if ($scope.tp.data[i].id == manage.id) {
                            $scope.tp.data.splice(i, 1);
                            break;
                        }
                    }
                })
            });
        }

        function setStorage() {
            localStorage.setItem('cols', JSON.stringify($scope.cols));
        }

        function clearForm() {
            $scope.manage = {};
            $scope.f_installation_date = null;
            $scope.f_to_contract_end = null;
            $scope.regions = [];
            $scope.region = {};
            $scope.cities = [];
            $scope.apparats = [];
            $scope.apparat = {};
            $scope.city = {};
            $scope.hospitals = [];
        }

        function addManage() {
            $scope.editedManage = {};
            clearForm();
            getRegions();
            getApparats();
            ngDialog.openConfirm({
                template: 'templates/dialogs/manage.html',
                scope: $scope,
                className: 'ngdialog-theme-plain custom-width',
            }).then(function (result) {
                result.hospital_id = $scope.hospital.selected.id;
                result.apparat_id = $scope.apparat.selected.id;
                result.installation_date = $scope.f_installation_date ? moment($scope.manage.installation_date).format("YYYY-MM-DD HH:mm:ss") : '';
                result.to_contract_end = $scope.f_to_contract_end ? moment($scope.manage.to_contract_end).format("YYYY-MM-DD HH:mm:ss") : '';
                console.log(result);
                var manage = result;
                $http.post('/manage', manage).then(function (result) {
                    getData();
                    Notification.success('Добавлено');
                    //$scope.hospitals.push(result.data);
                    //getHospitals();
                })
            });
        }

        function editManage(manage) {
            console.log(manage);
            $scope.manage = manage;
            $scope.manage.KMT = manage.KMT;
            $scope.apparat.selected = manage.apparat;
            $scope.region.selected = manage.hospital.region;
            $scope.city.selected = manage.hospital.city;
            $scope.hospital.selected = manage.hospital;
            if (moment(manage.installation_date).format('LL') == 'Invalid date') {
                $scope.f_installation_date = '';
            } else {
                $scope.f_installation_date = moment(manage.installation_date).format('LL');
            }
            if (moment(manage.to_contract_end).format('LL') == 'Invalid date') {
                $scope.f_to_contract_end = '';
            } else {
                $scope.f_to_contract_end = moment(manage.to_contract_end).format('LL');
            }

            getRegions();
            getCityByRegion(manage.hospital.region);
            getApparats();
            ngDialog.openConfirm({
                template: 'templates/dialogs/manage.html',
                scope: $scope,
                className: 'ngdialog-theme-plain custom-width',
            }).then(function (result) {
                result.hospital_id = $scope.hospital.selected.id;
                result.apparat_id = $scope.apparat.selected.id;
                result.installation_date = $scope.f_installation_date ? moment($scope.manage.installation_date).format("YYYY-MM-DD HH:mm:ss") : '';
                result.to_contract_end = $scope.f_to_contract_end ? moment($scope.manage.to_contract_end).format("YYYY-MM-DD HH:mm:ss") : '';
                var manage = result;
                $http.put('/manage/' + manage.id, manage).then(function (result) {
                    getData();
                    //$scope.hospitals.push(result.data);
                    clearForm();
                    //getHospitals();
                })
            });
        }

        $scope.setYear = function (newDate) {
            $scope.manage.production_date = moment(Date.parse(newDate)).format('YYYY');
        };

        $scope.saveManage = function (result) {
            result.hospital_id = $scope.hospital.selected.id;
            result.apparat_id = $scope.apparat.selected.id;
            result.installation_date = $scope.f_installation_date ? moment($scope.manage.installation_date).format("YYYY-MM-DD HH:mm:ss") : '';
            result.to_contract_end = $scope.f_to_contract_end ? moment($scope.manage.to_contract_end).format("YYYY-MM-DD HH:mm:ss") : '';
            console.log(result);
            var manage = result;
            $http.post('/manage', manage).then(function () {
                getData();
                Notification.success('Добавлено');
                $scope.f_installation_date = '';
                $scope.f_to_contract_end = '';
                result.to_contract = '';
                result.KMT = 0;
                result.warranty = '';
                result.production_date = '';
                result.contract = '';
                result.serial = '';
                result.supplier = '';
                //$scope.hospitals.push(result.data);
                //getHospitals();
            })
        };

        $scope.setContactEndDate = function (newDate) {
            $scope.f_to_contract_end = moment(newDate).format('LL');
        };

        $scope.setInstallationDate = function (newDate) {
            $scope.f_installation_date = moment(newDate).format('LL');
        };

        $scope.openExportDialog = function () {
            ngDialog.open({
                template: 'templates/dialogs/export.html',
                scope: $scope,
                className: 'ngdialog-theme-plain',
            })
        };

        $scope.export = function (fileName) {
            //console.log($scope.exportData);
            //console.log($scope.cols);
            var data = {
                data: [],
                columns: [],
                fileName: fileName
            };

            for (var column in $scope.cols) {
                if ($scope.cols[column].show) {
                    data.columns.push($scope.cols[column].title);
                }
            }

            for (var item in $scope.exportData) {
                var object = {};
                if ($scope.cols[0].show) {
                    object.region = $scope.exportData[item].hospital.region.name;
                }
                if ($scope.cols[1].show) {
                    object.city = $scope.exportData[item].hospital.city ? $scope.exportData[item].hospital.city.name : $scope.exportData[item].hospital.region.name;
                }
                if ($scope.cols[2].show) {
                    object.hospital = $scope.exportData[item].hospital.name;
                }
                if ($scope.cols[3].show) {
                    object.apparat = $scope.exportData[item].apparat.name;
                }
                if ($scope.cols[4].show) {
                    object.serial = $scope.exportData[item].serial;
                }
                if ($scope.cols[5].show) {
                    object.production_date = $scope.exportData[item].production_date;
                }
                if ($scope.cols[6].show) {
                    object.supplier = $scope.exportData[item].supplier;
                }
                if ($scope.cols[7].show) {
                    object.contract = $scope.exportData[item].contract;
                }
                if ($scope.cols[8].show) {
                    object.installation_date = $scope.exportData[item].installation_date;
                }
                if ($scope.cols[9].show) {
                    object.warranty = $scope.exportData[item].warranty;
                }
                if ($scope.cols[10].show) {
                    object.KMT = $scope.exportData[item].KMT;
                }
                if ($scope.cols[11].show) {
                    object.to_contract = $scope.exportData[item].to_contract;
                }
                if ($scope.cols[12].show) {
                    object.to_contract_end = $scope.exportData[item].to_contract_end;
                }
                data.data.push(object);
            }
            console.log(data);
            $http.post('/export', data).success(function (result) {
                window.location.href = '/exports/' + result;
            });
        };

        $scope.openComments = function (manage) {
            $scope.edditingManage = manage;
            getComments(manage.id);
            ngDialog.open({
                scope: $scope,
                template: 'templates/dialogs/comments.html',
                className: 'ngdialog-theme-plain custom-width2',
            })
        };

        function getComments(id) {
            console.log(id);
            $http.get('/comments', {params: {id: id}}).success(function (result) {
                $scope.comments = result;
            });

        }

        $scope.addComment = function () {
            ngDialog.openConfirm({
                scope: $scope,
                template: 'templates/dialogs/addComment.html'
            }).then(function (newComment) {
                saveComment(newComment);
            });
        };

        function saveComment(comment) {
            var data = {
                manage_id: $scope.edditingManage.id,
                comment: comment.comment
            };

            $http.post('/comments', data).then(function (result) {
                getComments(result.data.manage_id);
            })
        }

        $scope.deleteComment = function (comment) {
            $http.delete('/comments', comment);
        };


        $scope.selectFilterSerial = function (item, model) {
            if (item) {
                $http.post('/manage/getBySerial', item).then(function (result) {
                    $scope.tp = new NgTableParams({}, {dataset: result.data});
                })
            } else {
                getData();
            }
        };

        $scope.fserials = [];
        $scope.addManage = addManage;
        $scope.selectRegion = selectRegion;
        $scope.selectCity = selectCity;
        $scope.getRegions = getRegions;
        $scope.editManage = editManage;
        $scope.deleteManage = deleteManage;
        $scope.setStorage = setStorage;
        $scope.selectFilterRegion = selectFilterRegion;
        $scope.selectFilterCity = selectFilterCity;
        $scope.selectFilterHospital = selectFilterHospital;
        $scope.selectFilterHospitalByCity = selectFilterHospitalByCity;
        $scope.selectFilterApparat = selectFilterApparat;
        $scope.selectFilterApparatsByRegion = selectFilterApparatsByRegion;
        $scope.exportData = [];
        $scope.exportFileName = 'Новый файл';
        $scope.deletedManage = {};
        $scope.editedManage = {};
        $scope.manage = {};
        $scope.edditingManage = {};
        $scope.regions = [];
        $scope.region = {};

        $scope.fregions = [];
        $scope.fregion = {};

        $scope.apparats = [];
        $scope.apparat = {};

        $scope.fapparats = [];
        $scope.fapparat = {};

        $scope.cities = [];
        $scope.city = {};

        $scope.fcities = [];
        $scope.fcity = {};

        $scope.hospitals = [];
        $scope.hospital = {};

        $scope.fhospitals = [];
        $scope.fhospital = {};

        getCols();
        getData();
        getFilterData();
    }]);