/**
 * Created by PugachevIgor on 10.12.2015.
 */
app.controller('HospitalsListController', ['$scope', '$http', '$stateParams', 'ngDialog', 'NgTableParams',
    function ($scope, $http, $stateParams, ngDialog, NgTableParams) {
        var url = '/region/' + $stateParams.region_id + '/city/' + $stateParams.city_id + '/hospital';

        var getHospitals = function () {
            $http.get('/hospital').then(function (result) {
                $scope.hospitals = result.data;
                $scope.tableParams = new NgTableParams({
                    page: 1,
                    count: 10,
                }, {
                    filterDelay: 0,
                    dataset: $scope.hospitals
                });
            })
        };

        $scope.cols = [
            { field: "name", title: "Название больницы", show: true },
        ];


        var addHospital = function () {
            $scope.editedHospital = {};
            clearForm();
            ngDialog.openConfirm({
                template: 'templates/dialogs/hospital.html',
                scope: $scope,
                className: 'ngdialog-theme-plain custom-width',
            }).then(function (result) {
                var hospital = result;
                $http.post('/hospital', hospital).then(function (result) {
                    $scope.hospitals.push(result.data);
                    clearForm();
                    getHospitals();
                })
            });
        };

        var createHospitalAndNew = function () {
            var hospital = $scope.hospital;
            $http.post('/hospital', hospital).then(function (result) {
                $scope.hospitals.push(result.data);
                clearForm();
                getHospitals();
            })
        };

        var getRegions = function () {
            $http.get('/region').then(function (result) {
                $scope.regions = result.data;
                $scope.regionsFilter = result.data;
            })
        };

        var getCities = function (region) {
            $http.get('/region/' + region.id + '/city').then(function (result) {
                $scope.cities = result.data;
            })
        };

        var selectRegion = function (item) {
            $scope.hospital.region_id = item.id;
            $http.get('/region/' + item.id + '/city').then(function (result) {
                $scope.cities = result.data;
                if(result.data.length) {
                    $scope.city = result.data[0];
                    $scope.city.selected = result.data[0];
                    $scope.hospital.city_id = result.data[0].id;
                } else {
                    $scope.city = {};
                    $scope.city.selected = {};
                    $scope.hospital.city_id = null;
                }
            })
        };

        function getHospitalByRegion(region) {
            $http.get('/hospital/getByRegion/' + region.id).then(function (result) {
                $scope.hospitals = result.data;
                $scope.tableParams = new NgTableParams({
                    page: 1,
                    count: 10,
                }, {
                    filterDelay: 0,
                    dataset: $scope.hospitals
                });
            });
        }

        var deleteHospital = function (hospital) {
            $scope.deletedHospital = hospital;

            ngDialog.openConfirm({
                scope: $scope,
                template: 'templates/dialogs/deleteHospital.html',
            }).then(function (value) {
                $http.delete('/hospital/' + hospital.id).then(function () {
                    for(var i in $scope.tableParams.data) {
                        if($scope.tableParams.data[i].id == hospital.id) {
                            $scope.tableParams.data.splice(i, 1);
                            break;
                        }
                    }
                })
            });
        };


        function selectFilterRegion(region) {
            getHospitalByRegion(region);
        }


        var editHospital = function (hospital) {

            $scope.editedHospital = hospital;
            $scope.hospital = hospital;
            $scope.region.selected = hospital.region;
            getRegions();
            getCities(hospital.region);
            $scope.city.selected = hospital.city;

            ngDialog.openConfirm({
                template: 'templates/dialogs/hospital.html',
                scope: $scope,
                className: 'ngdialog-theme-plain custom-width',
            }).then(function (result) {
                var hospital = result;
                $http.put('/hospital/' + hospital.id, hospital).then(function (result) {
                    clearForm();
                    getHospitals();
                })
            });
        };

        var selectCity = function (item) {
            $scope.hospital.city_id = item.id;
        };

        var clearForm = function () {
            $scope.hospital = {};
            $scope.region = {};
            $scope.city = {};
            $scope.cities = [];
        };

        $scope.editContacts = function (hospital) {
            $scope.edditingHospital = hospital;
            $http.get('/contacts', {params: {id: hospital.id}}).success(function (result) {
                $scope.contacts = result;
                ngDialog.open({
                    scope: $scope,
                    template: 'templates/dialogs/contacts.html'
                })
            });
        };

        $scope.addContact = function () {
            ngDialog.openConfirm({
                scope: $scope,
                template: 'templates/dialogs/addContact.html'
            }).then(function (newContact) {
                saveContact(newContact);
            });
        };

        $scope.deleteContact = function (contact, $index) {
            $http.delete('/contacts/' + contact.id).success(function () {
                $scope.contacts.splice($index, 1);
            });
        };

        function saveContact(newContact) {
            var data = {
                id: $scope.edditingHospital.id,
                name: newContact.name,
                position: newContact.position,
                phone: newContact.phone
            };

            $http.post('/contacts', data).success(function (result) {
                $scope.contacts.push(result);
            })
        };


        $scope.edditingHospital = {};
        $scope.hospitals = [];
        $scope.hospital = {};
        $scope.regions = [];
        $scope.region = {};
        $scope.cities = [];
        $scope.regionFilter = {};
        $scope.regionsFilter = [];
        $scope.citiesFilter = [];
        $scope.cityFilter = {};

        $scope.deletedHospital = {};
        $scope.selectedRegion = {};
        $scope.selectedCity = {};
        $scope.editedHospital = {};
        $scope.city = {};

        $scope.getCities = getCities;
        $scope.addHospital = addHospital;
        $scope.selectRegion = selectRegion;
        $scope.selectCity = selectCity;
        $scope.editHospital = editHospital;
        $scope.deleteHospital = deleteHospital;
        $scope.createHospitalAndNew = createHospitalAndNew;
        $scope.selectFilterRegion = selectFilterRegion;
        $scope.contacts = [];
        $scope.contact = {};
        $scope.newContact = {};

        getRegions();
        getHospitals();
    }]);