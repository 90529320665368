/**
 * Created by PugachevIgor on 10.12.2015.
 */
app.controller('CitiesListController', ['$scope', '$http', '$stateParams', 'ngDialog',
    function ($scope, $http, $stateParams, ngDialog) {
        var url = '/region/' + $stateParams.region_id + '/city';

        $scope.region = {
            isCity: false
        };

        $scope.checkIsCity = function (region) {
            $http.post('/region/' + $stateParams.region_id + '/isCity', region).then(function (result) {
                console.log(result.data);
            })
        };

        var getCities = function () {
            $http.get('/region/' + $stateParams.region_id + '/isCity').then(function (result) {
                $scope.region.isCity = result.data.isCity == 1?true:false;
                if(result.data.isCity == 0) {
                    $http.get(url).then(function (result) {
                        $scope.cities = result.data;
                    })
                }
            });
        };

        var addCity = function (city) {
            city.region_id = $stateParams.region_id;
            $http.post(url, city).then(function (result) {
                $scope.cities.push(result.data);
                city.name = null;
            })
        };
        var updateCity = function (city) {
            $http.put(url + '/' + city.id, city).then(function (result) {
                console.log(result.data);
            });
        };

        var deleteCity = function (index, event) {
            event.stopPropagation();
            $scope.deletedCity = $scope.cities[index];
            ngDialog.openConfirm({
                scope: $scope,
                template: 'templates/dialogs/deleteCity.html',
            }).then(function (value) {
                $http.delete(url + '/' + $scope.cities[index].id).then(function () {
                    $scope.cities.splice(index, 1);
                })
            });
        };

        $scope.cities = [];
        $scope.newCity = {};

        $scope.addCity = addCity;
        $scope.updateCity = updateCity;
        $scope.deleteCity = deleteCity;

        getCities();
    }]);